import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import MemberList from "./member-list";

import * as styles from "../styles/components/about-team.module.css";

const Team = (props) => {

  const {
    heading,
    teamMembers
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {heading && <h2 className={cn(styles.heading, "h3")}>{heading}</h2>}
        {teamMembers && <MemberList items={teamMembers} />}
      </Container>
    </div>
  );
}

export default Team;
