import React from "react";
import PostItem from "./post-item";

import * as styles from "../styles/components/fc-press-releases.module.css";

const PressRelease = (props) => {

  const {
    _key,
    pressRelease
  } = props;

  return (
    <div className={styles.root} key={_key} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      {pressRelease && <PostItem {...pressRelease} />}
    </div>
  );
}

export default PressRelease;
