import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/fc-image.module.css";

const Image = (props) => {

  const {
    _key,
    image,
    rightAligned
  } = props;

  return (
    <div className={cn(styles.root, rightAligned && styles.right)} key={_key} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      {image && image.asset && (
        <img
          src={imageUrlFor(buildImageObj(image))
            .auto("format")
            .url()}
          alt={image.alt}
        />
      )}
    </div>
  );
}

export default Image;
