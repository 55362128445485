import React from "react";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/member-list.module.css";

function MemberList({ items }) {
  return (
    <ul className={styles.root}>
      {items.map(({ _key, image, name, position, _rawDescription }) => {
        return (
          <li key={_key} className={styles.item}>
            {image && image.asset && (
              <div className={styles.image} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                <img
                  src={imageUrlFor(buildImageObj(image))
                    .auto("format")
                    .url()}
                  alt={image.alt}
                />
              </div>
            )}
            {(name || position || _rawDescription) && (
              <div className={styles.text} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
                <div className="containerContent">
                  {name && <h3 className={cn(styles.heading, "h2")}>{name}</h3>}
                  {position && <div className={cn(styles.position, "h3")}>{position}</div>}
                  {_rawDescription && <PortableText blocks={_rawDescription} />}
                </div>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default MemberList;
