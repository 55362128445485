import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageBanner from "../components/page-banner";
import FlexibleContent from "../components/flexible-content";
import Team from "../components/about-team";
import NextPage from "../components/next-page";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query AboutPageQuery {
    about: sanityAbout {
      pageBanner {
        text
        anchorLinkText
        image {
          ...SanityImage
          alt
        }
        imageMobile {
          ...SanityImage
          alt
        }
      }
      flexibleContent {
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
          rightAligned
        }
        ... on SanityFcImage {
          _key
          _type
          image {
            ...SanityImage
            alt
          }
          rightAligned
        }
      }
      aboutTeam {
        heading
        teamMembers {
          _key
          image {
            ...SanityImage
            alt
          }
          name
          position
          _rawDescription
        }
      }
      nextPage {
        link {
          linkUrl
          text
        }
        _rawDescription
        image {
          ...SanityImage
          alt
        }
        imageMobile {
          ...SanityImage
          alt
        }
      }
      description
    }
  }
`;

const AboutPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const about = (data || {}).about;

  return (
    <Layout>
      <Seo
        title={about.pageBanner.text}
        description={about.description}
      />

      {about.pageBanner && <PageBanner {...about.pageBanner} />}

      <div id="main">

        {about.flexibleContent && <FlexibleContent items={about.flexibleContent} />}

        {about.aboutTeam && <Team {...about.aboutTeam} />}

        {about.nextPage && <NextPage {...about.nextPage} />}
        
      </div>
    </Layout>
  );
};

export default AboutPage;
