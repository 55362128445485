import React from "react";
import Container from "./container";
import ContentBlock from "./fc-content-block";
import ContentBlockWithMedia from "./fc-content-block-with-media";
import Image from "./fc-image";
import PressRelease from "./fc-press-release";

import * as styles from "../styles/components/flexible-content.module.css";

function FlexibleContent({ items }) {
  return (
    <div className={styles.root}>
      <Container>
        {items.map(node => {
          switch (node._type) {
            case `fcContentBlock`:
              return <ContentBlock {...node} />
            case `fcContentBlockWithMedia`:
              return <ContentBlockWithMedia {...node} />
            case `fcImage`:
              return <Image {...node} />
            case `pressReleaseReference`:
              return <PressRelease {...node} />
            default:
              return null
          }
        })}
      </Container>
    </div>
  );
}

export default FlexibleContent;
