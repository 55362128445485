import React from "react";
import PortableText from "./portableText";
import { cn } from "../lib/helpers";
import ContentBlockMedia from "./fc-content-block-media";

import * as styles from "../styles/components/fc-content-block-with-media.module.css";

const ContentBlockWithMedia = (props) => {

  const {
    _key,
    _rawContent,
    image,
    video,
    mediaRightPositioned
  } = props;

  return (
    <div className={cn(styles.root, mediaRightPositioned && styles.mediaRight)} key={_key} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      <div className={styles.media}>
        {(image || video) && <ContentBlockMedia image={image} video={video} />}
      </div>
      <div className={styles.text}>
        <div className="containerContent">
          {_rawContent && <PortableText blocks={_rawContent} />}
        </div>
      </div>
    </div>
  );
}

export default ContentBlockWithMedia;
