import React from "react";
import PortableText from "./portableText";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/fc-content-block.module.css";

const ContentBlock = (props) => {

  const {
    _key,
    _rawContent,
    rightAligned
  } = props;

  return (
    <div className={cn(styles.root, rightAligned && styles.right)} key={_key} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      <div className="containerContent">
        {_rawContent && <PortableText blocks={_rawContent} />}
      </div>
    </div>
  );
}

export default ContentBlock;
