import React, { useRef } from "react";
import Icon from "./icon";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/fc-content-block-media.module.css";

const ContentBlockMedia = (props) => {

  const {
    image,
    video
  } = props;

  const mediaRef = useRef(null);

  function handlePlayVideo() {
    mediaRef.current.parentElement.classList.add(styles.active);
    mediaRef.current.src = mediaRef.current.src + '&autoplay=1';
  }

  return (
    <div className={styles.root}>
      {image && image.asset && (!video || !video.embedId) && (
        <div className={styles.image}>
          <img
            src={imageUrlFor(buildImageObj(image))
              .auto("format")
              .url()}
              alt={image.alt}
          />
        </div>
      )}

      {video && video.embedId && (
        <div className={styles.video}>
          <div className={styles.videoWrap}>
            {video.cover && video.cover.asset && (
              <div className={styles.cover}>
                <img
                  src={imageUrlFor(buildImageObj(video.cover))
                    .auto("format")
                    .url()}
                  alt={video.cover.alt}
                />
              </div>
            )}
            <div className={styles.action} onClick={handlePlayVideo} onKeyDown={handlePlayVideo} role="button" tabIndex={0}>
              <Icon symbol="play" />
            </div>
            <div className={styles.player}>
              <iframe title="Content video" src={"https://player.vimeo.com/video/"+video.embedId+"&transparent=0"} width="640" height="360" frameborder="0" ref={mediaRef} allow="autoplay" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentBlockMedia;
